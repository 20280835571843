<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-title>
        Ajuste Físico
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        >
          <template v-slot:append>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  medium
                  color="primary"
                  class="mt-0 pt-0"
                  @click="dialogCodgiBarras = true"
                >
                  <v-icon v-on="on">
                    mdi-barcode
                  </v-icon>
                </v-btn>
              </template>
              Pesquisar
            </v-tooltip>
            <v-tooltip
              bottom
            >
              Pesquisar
            </v-tooltip>
          </template>
        </v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="listaAjusteFisico"
        :search="search"
      >
        <template v-slot:item.qtEstoque="{ item }">
          {{ formatPrice(item.qtEstoque) }}
        </template>
        <template v-slot:item.qtReservado="{ item }">
          {{ formatPrice(item.qtReservado) }}
        </template>
        <!--templet botão editar -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            x-larger
            class="mr-2"
            @click="onClickSelect(item)"
          >
            mdi-clipboard-edit-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogCodgiBarras"
      fullscreen
    >
      <modalLeituraCodBarra @onPesquisaCodigoBarras="onPesquisaCodigoBarras" />
    </v-dialog>
    <v-dialog
      v-model="dialogLoja"
      max-width="300"
      persistent
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          Selecione a Loja
        </v-toolbar>
        <v-card-text>
          <componenteLoja
            :id="loja"
            dense
            :flparamentrovenda="false"
            @onDadosLoja="onDadosLoja"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAddAjusteFisico"
      max-width="600"
    >
      <componenteajustefisico
        v-if="dialogAddAjusteFisico"
        :loja="lojaselecionada"
        :cdsubitem="cdSubItem"
        :cditem="cdItem"
        :cdunidade="cdUnidade"
        :item="item"
        :qtestoque="qtEstoque"
        @onSairAjusteFisico="onSairAjusteFisico"
      />
    </v-dialog>
  </v-container>
</template>
  <script>
  import ajustefisicoRep from './../../../components/services/ajustefisicoRepository'
  import transacaoRep from './../../../components/services/transacaoRepository'
  import modalLeituraCodBarra from './../../componentes/componente-Leitura-CodigoBarra.vue'
  import componenteLoja from './../../componentes/componente-loja.vue'
  import componenteajustefisico from './../../componentes/componente-ajustefisico.vue'

  export default {
    components: {
      modalLeituraCodBarra,
      componenteLoja,
      componenteajustefisico,
    },
    data () {
      return {
        lojaselecionada: 0,
        dialogLoja: false,
        search: '',
        listaAjusteFisico: [],
        headers: [
          {
            text: 'Loja',
            align: 'start',
            value: 'nmloja',
            width: 100,
          },
          {
            text: 'Cód',
            align: 'end',
            value: 'cdItem',
            width: 30,
          },
          {
            text: '',
            align: 'start',
            value: 'cdSubItem',
            width: 5,
          },
          {
            text: 'Descrição',
            align: 'start',
            value: 'nmItem',
            width: 200,
          },
          {
            text: 'Unid.',
            align: 'start',
            value: 'cdUnidade',
            width: 10,
          },
          {
            text: 'Físico',
            align: 'end',
            value: 'qtEstoque',
            width: 100,
          },
          {
            text: 'Reservado',
            align: 'end',
            value: 'qtReservado',
            width: 100,
          },
          { text: '', value: 'actions', width: 100, sortable: false },
        ],
        acessoLiberado: false,
        dialogCodgiBarras: false,
        dialogAddAjusteFisico: false,
        dialogEtiqueta: false,
        loja: 0,
        cdItem: 0,
        cdSubItem: '',
        cdUnidade: '',
        item: '',
        qtEstoque: 0,
      }
    },

    async mounted () {
      this.dialogLoja = true
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        await this.getTransacao()
        if (this.acessoLiberado === false) {
          this.$router.replace('/v1')
        }
      }
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          this.listaAjusteFisico = []
          await ajustefisicoRep.getList(this.lojaselecionada).then(response => {
            if (response.data.status === 'sucesso') {
              this.$store.commit('setSplashScreen', false)
              this.listaAjusteFisico = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            } else {
              this.$swal({
                icon: 'error',
                text: response.data.mensagemErro,
              })
              this.$store.commit('setSplashScreen', false)
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      async getTransacao () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FP012AJU',
        }
        await transacaoRep.getTransacao(dados).then(response => {
          this.acessoLiberado = true
          if (response.data.flHabAlt === true) { this.telaHabAlt = true } else { this.telaHabAlt = false }
        }).catch(Error => {
          this.$store.commit('setSplashScreen', false)
          this.acessoLiberado = false
          this.$swal({
            icon: 'error',
            text: 'Acesso não permitido, para solicitar o acesso passar o seguinte código, FP012AJU.',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) return false
          })
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      onPesquisaCodigoBarras (codigoBarras) {
        // console.log(codigoBarras)
        this.dialogCodgiBarras = false
        this.search = codigoBarras
      },
      onSelectLoja (value) {
        // console.log(value)
        this.loja = value.cdLoja
        this.getList()
      },
      onDadosLoja (dados) {
        this.lojaselecionada = dados.cdLoja
        this.getList()
        this.dialogLoja = false
      },
      onClickSelect (item) {
        this.cdItem = item.cdItem
        this.cdSubItem = item.cdSubItem
        this.cdUnidade = item.cdUnidade
        this.item = item.nmItem
        this.qtEstoque = item.qtEstoque
        this.dialogAddAjusteFisico = true
      },
      onSairAjusteFisico () {
        this.dialogAddAjusteFisico = false
        this.getList()
      },
    },
  }
  </script>
  <style>
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
      font-size:8pt;
    }
  </style>
