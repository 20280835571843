import Repository from './repository'

const resource = '/ajustefisico'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  getList (id) {
    return Repository.get(`${resource}/getlist?loja=${id}`)
  },
  getAjusteFisico (loja) {
    return Repository.get(`${resource}/getList?loja=${loja}`)
  },
  postAjusteItem (dados) {
    return Repository.post(`${resource}`, dados)
  },
}
