<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <v-spacer />
      <v-btn
        class="mx-2"
        small
        fab
        color="light-green accent-4"
        @click="onSair"
      >
        <v-icon class="white--text">
          mdi-exit-to-app
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
      class="d-flex justify-center"
    >
      <!-- <v-quagga
        :on-detected="logIt"
        :reader-size="readerSize"
        :reader-types="['code_128_reader']"
      /> -->
      <stream-barcode-reader
        @decode="onDecode"
      />
    </v-container>
    <p>{{ retornoLeitura }}</p>
  </v-card>
</template>

<script>
  import { StreamBarcodeReader } from 'vue-barcode-reader'
  export default {
    components: {
      StreamBarcodeReader,
    },
    data () {
      return {
        readerSize: {
          width: 640,
          height: 480,
        },
        detecteds: [],
        retornoLeitura: '',
      }
    },
    methods: {

      onDecode (text) {
        this.retornoLeitura = text
        this.$emit('onPesquisaCodigoBarras', text)
      },
      onSair () {
        this.$emit('onSair', '')
      },
      // onLoaded () {
      //   this.retornoLeitura = 'Carregou'
      // },
      // onDecode (text) {
      //   this.retornoLeitura = text
      // },
    },
  }
</script>
