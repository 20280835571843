<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Ajustar Item - {{ item }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onAjusteProduto"
        >
          <v-icon class="white--text">
            mdi-plus
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-container>
        <v-row>
          <v-col
            cols="6"
            md="3"
          >
            <v-text-field
              v-model="qtd"
              v-currency="currencyAjuste"
              label="Qtd"
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
  import ajusteRep from './../../components/services/ajustefisicoRepository'
  export default {
    props: {
      item: {
        type: String,
        required: true,
      },
      loja: {
        type: Number,
        required: true,
      },
      cditem: {
        type: Number,
        required: true,
      },
      cdsubitem: {
        type: String,
        required: true,
      },
      cdunidade: {
        type: String,
        required: true,
      },
      qtestoque: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        obs: '',
        qtd: '0',
        currencyAjuste: {
          locale: 'pt-br',
          currency: null,
          valueAsInteger: false,
          distractionFree: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: false,
        },
      }
    },
    methods: {
      async onAjusteProduto () {
        this.$store.commit('setSplashScreen', true)
        var qtd2 = this.qtd
        var qtestoqueatual = this.qtestoque
        // console.warn(this.qtestoque)
        var dadosAjuste = {
          cdLoja: this.loja,
          cdItem: this.cditem,
          cdSubItem: this.cdsubitem,
          cdUnidade: this.cdunidade,
          qtEstoque: this.formatPrice(qtd2.replace('.', '').replace(',', '.') - qtestoqueatual).replace('.', '').replace(',', '.'),

        }
        await ajusteRep.postAjusteItem(dadosAjuste).then(response => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'success',
            text: 'Item ajustado!',
          })
          this.$emit('onSairAjusteFisico')
        }).catch(error => {
          this.maxajuste = 0
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: 'Erro ao ajustar o item:\n' + error,
          })
        })
        this.$emit('onAjusteProduto')
      },
      onSair () {
        this.$emit('onSairAjusteFisico')
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
</script>
