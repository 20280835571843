<template>
  <v-select
    v-model="cdLoja"
    :items="listaLoja"
    item-text="nmLoja"
    item-value="cdLoja"
    label="Loja"
    outlined
    :autofocus="autofocus"
    :readonly="readonly"
    hide-details
    :dense="dense"
    @change="onSelect"
  />
</template>
<script>
  import lojaRep from './../../components/services/lojaRepository'
  import paramvendaRep from './../../components/services/paramvendaRepository'

  export default {
    props: {
      dense: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
        default: 0,
      },
      carregarlojainicial: {
        type: Boolean,
        default: false,
      },
      flparamentrovenda: {
        type: Boolean,
        default: true,
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        listaLoja: [],
        cdLoja: 0,
        lojaparamtros: {
          cdLoja: 0,
          vldesconto: 0.00,
        },
      }
    },
    created () {
      this.getList()
      this.id === 0 ? this.cdLoja = 0 : this.cdLoja = this.id
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await lojaRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaLoja = response.data.dados
              if (this.carregarlojainicial) {
                this.cdLoja = this.listaLoja[0].cdLoja
                this.onLojaInicial(this.listaLoja[0].cdLoja)
              }
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {
          this.$store.commit('setSplashScreen', false)
        }
      },
      async getParamVenda () {
        try {
          this.$store.commit('setSplashScreen', true)
          await paramvendaRep.getId(this.cdLoja).then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.lojaparamtros.vldesconto = response.data.dados.vlDesconto
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {
          this.$store.commit('setSplashScreen', false)
        }
      },
      async getDadosLoja (id) {
        try {
          this.$store.commit('setSplashScreen', true)
          await lojaRep.getId(id).then(response => {
            this.$store.commit('setSplashScreen', false)
            response.data.result.cdLoja = id
            this.$emit('onDadosLoja', response.data.result)
          }).catch(error => {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {
          this.$store.commit('setSplashScreen', false)
        }
      },
      async onSelect () {
        // this.readonly = true
        this.lojaparamtros.cdLoja = this.cdLoja
        // Flag - Nem sempre se faz necessario carregar os parametro de vendas
        if (this.flparamentrovenda === true) { await this.getParamVenda() }
        this.$emit('onSelectLoja', this.lojaparamtros)
        this.getDadosLoja(this.cdLoja)
      },
      async onLojaInicial (loja) {
        this.$emit('onLojaInicial', loja)
      },
    },
  }
</script>
